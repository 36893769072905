/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/SimpleFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import Contact from "../Author/sections/Contact";

// Image
import bgImage from "assets/images/Iceberg_Sunset_option_3.jpg";

function ContactUs() {
  return (
    <>
      <MKBox
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { rgba, linearGradient } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <Navbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact",
            label: "Contact Us",
            color: "info",
          }}
          sticky
          brand="CleanWeb Solutions LLC"
        />

        <Contact />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function Testimonials() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Trusted by Businesses</MKTypography>
          <MKBox my={2} />
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Nationwide
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            From small startups to large enterprises, our clients trust us to deliver innovative web
            solutions.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Julia Harper"
              date="2 weeks ago"
              review="Our new website has transformed our business. The team was attentive, creative, and turned our vision into reality. Highly recommend!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Marcus Finch"
              date="1 month ago"
              review="Incredible service! They revamped our online store, making it more user-friendly and visually appealing. Sales have doubled since the launch."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Liam Chen"
              date="3 months ago"
              review="As a tech startup, we needed a sleek website fast. They delivered beyond expectations, with clear communication throughout the process."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        {/* <MKTypography variant="h4" textAlign="center" mb={4}>
          Our Partners
        </MKTypography> */}
        <Grid container spacing={3} justifyContent="center">
          {/* Example Logos of Partner Companies or Clients */}
          {/* Assuming you replace these with logos relevant to your actual clients or partners */}
          {/* <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src="/path/to/client-logo1.svg"
              alt="Client Name 1"
              width="100%"
              opacity={0.6}
            />
          </Grid> */}
          {/* <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src="/path/to/client-logo2.svg"
              alt="Client Name 2"
              width="100%"
              opacity={0.6}
            />
          </Grid> */}
          {/* Add more logos as needed */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;

import AboutUs from "layouts/pages/landing-pages/about-us";
import Contact from "pages/LandingPages/ContactUs";

const routes = [
  {
    name: "About Us",
    route: "/about",
    component: <AboutUs />,
  },
  {
    name: "Login",
    href: "https://dashboard.clean-web.solutions",
  },
  {
    name: "Contact Us",
    route: "/contact",
    component: <Contact />,
  },
];

export default routes;

// Importing images
import NbeImage from "assets/images/past-work/NBE.PNG";
import KUSJUImage from "assets/images/past-work/KUSJU.PNG";
import RadioImage from "assets/images/past-work/RadiolucentTech.PNG"; // Assuming this image is now local
import SizeMattersImage from "assets/images/past-work/SizeMattersMCCattery.PNG";

// Data mapping with local image references
export default [
  {
    image: NbeImage,
    name: "NBE Event Group",
    href: "https://www.nbeeventgroup.com/",
  },
  {
    image: KUSJUImage,
    name: "Kansas University In Puerto Rico",
    href: "https://www.kusju.com/",
  },
  {
    image: RadioImage,
    name: "Radiolucent Surgical Instruments",
    href: "https://www.radiolucent.tech/",
  },
  {
    image: SizeMattersImage,
    name: "S.M. M.C. Cattery",
    href: "https://www.sizemattersmccattery.com/",
  },
];

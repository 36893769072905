/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import Navbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/SimpleFooter";

// About Us page sections
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/aurora.jpg";

import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import developImg from "../../../assets/images/develop.jpg";
import designImg from "../../../assets/images/design.jpg";
import metricImg from "../../../assets/images/metrics.jpg";
import supportImg from "../../../assets/images/support.jpg";

import "./styles.css";

function AboutUs() {
  return (
    <>
      {/*           type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default", */}

      <Navbar
        routes={routes}
        action={{
          type: "internal",
          route: "/contact",
          label: "Contact Us",
          color: "info",
        }}
        sticky
        brand="CleanWeb Solutions LLC"
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              className="about-title"
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Work with our talented Team
            </MKTypography>
            <br />
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
              className="about-text"
            >
              At Clean Web Solutions, we&apos;re driven by a simple yet powerful mission: to provide
              affordable, custom-built websites that empower small businesses to thrive online. In
              today&apos;s digital landscape, having a strong online presence is no longer
              optional—it&apos;s essential. Recognizing the challenges small businesses face in
              finding cost-effective web development services, we stepped in to fill that gap.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="card-container">
          <div className="card-content">
            <CenteredBlogCard
              image={developImg}
              title="Custom Website Solutions"
              description=" Every business is unique, with its own set of challenges and requirements. Whether you're looking to launch a comprehensive online store, an engaging blog, or a portfolio to display your work, our team has the expertise to deliver custom website solutions that fit your specific business needs. We work closely with you to understand your vision and objectives, ensuring the final product is not just a website, but a tool that drives growth and success."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </div>

          <div className="card-content">
            <CenteredBlogCard
              image={designImg}
              title="Website Design"
              description="Your website is often the first impression potential customers have of your business. With this in mind, we craft stunning, user-friendly designs that not only captivate your audience but also reflect the essence of your brand. Our design process focuses on creating visually appealing, easy-to-navigate sites that communicate your message clearly and showcase your products or services in the best light possible."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </div>

          <div className="card-content">
            <CenteredBlogCard
              image={metricImg}
              title="Website Optimization"
              description=" In the fast-paced online world, a slow or poorly optimized website can significantly hinder your business's growth. Our website optimization services ensure that your site is lightning-fast, secure, and optimized for search engines. By focusing on the technical aspects of SEO, user experience, and website security, we boost your site's performance, improve your search engine rankings, and provide your visitors with a seamless browsing experience."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </div>

          <div className="card-content">
            <CenteredBlogCard
              image={supportImg}
              title="Ongoing Support"
              description=" We believe that our relationship with our clients doesn't end with the launch of their website. Our ongoing support service is designed to provide you with peace of mind and ensure your website continues to perform at its best. Whether you need updates, have questions, or require advice on expanding your online presence, our friendly and experienced team is always on hand to offer their support and expertise."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </div>
        </div>

        <Featuring />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;

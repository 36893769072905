import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function Contact() {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: "",
    reason: "",
    message: "",
  });

  // State for form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);

  // Update form data state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = () => {
    return formData.name && formData.reason && formData.message;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValid()) {
      alert("Please fill in all fields.");
      return;
    }

    setIsSubmitting(true);

    const endpoint =
      "https://us-central1-cleanweb-app.cloudfunctions.net/CleanWebAPI/sendContactForm"; // Replace with your endpoint URL

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Process response here
      console.log("Form submitted successfully");
      alert("Thank you! Your message has been sent.");
      // setFormSubmitted(true);
      setFormData({
        name: "",
        reason: "",
        message: "",
      });
    } catch (error) {
      console.error("There was a problem with your submission: ", error);
      alert("Error sending message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <MKBox component="section" py={{ xs: 0, lg: 6 }} style={{ paddingTop: "20vh" }}>
        <Container>
          <Grid container item>
            <MKBox width="100%" borderRadius="xl" shadow="xl" mb={6} sx={{ overflow: "hidden" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  style={{ background: "linear-gradient(#6495ED, #4169E1)" }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Contact Information
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Fill up the form and our Team will get back to you within 24 hours.
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          (+1) 317 666 3170
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          support@clean-web.solutions
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={7} style={{ backgroundColor: "white" }}>
                  <MKBox component="form" p={2} onSubmit={handleSubmit}>
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography variant="h2" mb={1}>
                        Reach Out To Our Team
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} mb={3}>
                          <MKInput
                            name="name"
                            variant="standard"
                            label="Name"
                            placeholder="Personal / Company Name"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} mb={3}>
                          <MKInput
                            name="reason"
                            variant="standard"
                            label="Reason For Reaching Out"
                            placeholder="How can we assist you?"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={formData.reason}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} mb={3}>
                          <MKInput
                            name="message"
                            variant="standard"
                            label="Your message"
                            placeholder="I would like help with..."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                            value={formData.message}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid container item justifyContent="flex-end">
                          <MKButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            disabled={!isFormValid() || isSubmitting}
                          >
                            Send Message
                          </MKButton>
                        </Grid>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </div>
  );
}

export default Contact;
